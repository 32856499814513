<template>
  <!-- <a-page-header @back="() => $router.go(-1)" title="PROGRAMACIÓN DE ENVÍOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Vigilancia</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Programación de envíos</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header> -->

  <PageHeader
    title="PROGRAMACIÓN DE ENVÍOS"
    :level1="{ title: 'Vigilancia', route: '/menu' }"
    :level2="{ title: 'Programación de envíos', route: '/' }"
    goBack
  />

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Filtros</legend>
      <ProgramacionEnviosFiltro @fetchData="fetchData" />
    </fieldset>

    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Acciones</legend>

      <ProgramacionEnviosAcciones
        :isDisabled="isDisabled"
        :mensaje="mensaje"
        @handleScript="handleScript"
        @handleAdd="handleAdd"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handleChangeState="handleChangeState"
      />
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :columns="columns"
      :row-selection="rowSelection"
      :data-source="data"
      size="small"
      :pagination="false"
      :loading="isLoading"
      :custom-row="customRow"
      rowKey="idprogramacion"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
      </template>

      <template #activo="{ text: activo }">
        <span>
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Activado" : "Desactivado" }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-col md:flex-row gap-4 items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="currentPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="reloadFetch">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer>

  <!-- <FooterTotalDocs
    :currentPage="currentPage"
    :pageSize="pageSize"
    :totalDocs="totalDocs"
    @fetchData="fetchData"
  /> -->

  <!--------------- Modal--------------->
  <ProgramacionEnviosAddEdit
    v-if="isModalVisible"
    :mensaje="mensaje"
    :currentPage="currentPage"
    @closeModal="closeModal"
    @clearSelectedArrays="clearSelectedArrays"
    @fetchData="fetchData"
  />
</template>

<script>
import { ref, reactive, onMounted, watch, computed, toRaw, createVNode } from "vue";
import { columns } from "./utilsProgramacionEnvios";
import ProgramacionEnviosAddEdit from "./ProgramacionEnviosAddEdit.vue";
import ProgramacionEnviosAcciones from "./ProgramacionEnviosAcciones.vue";
import ProgramacionEnviosFiltro from "./ProgramacionEnviosFiltro.vue";
// import router from "@/router";
import UsuariosApi from "@/api/user";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import ProgramacionApi from "@/api/sms_programacion";
import EjecuteApi from "@/api/ejecute";
import PageHeader from "@/components/PageHeader.vue";
import FooterTotalDocs from "@/components/FooterTotalDocs.vue";
import { Modal, notification } from "ant-design-vue";
import { useStore } from "vuex";

export default {
  components: {
    PageHeader,
    FooterTotalDocs,
    ProgramacionEnviosAddEdit,
    ProgramacionEnviosFiltro,
    ProgramacionEnviosAcciones,
    // icons
    ExclamationCircleOutlined,
    ReloadOutlined
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const currentPage = ref(1);
    const totalDocs = ref(null);
    const isModalVisible = ref(false);
    const mensaje = ref(null);
    const data = ref([]);
    const isLoading = ref(false);
    const isDisabled = ref(true);
    const state = reactive({
      selectedRowKeys: []
      // rowActiveStatus: []
    });
    const { establecimiento } = store.state.user.usuario;

    // const goBack = () => router.back();
    const showModal = () => {
      isModalVisible.value = true;
    };

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          mensaje.value = toRaw(selectedRows[0]);
        }
      };
    });

    const customRow = (record) => {
      return {
        onDblclick: () => {
          clearSelectedArrays();
        }
      };
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
      // state.rowActiveStatus = [];
    };

    const handleAdd = () => {
      mensaje.value = {
        mensaje: "",
        frecuencia: "",
        idgrupo: "",
        idperiodicidad: "",
        idprogramacion: ""
      };
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const handleScript = () => {
      const info = toRaw(mensaje.value);
      EjecuteApi.script(info.idgrupo)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      notification.success({
        message: "Operación exitosa",
        description: `Se ha enviado sus mensajes satistactoriamente.`
      });
    };

    const fetchData = (currentPage = 1) => {
      const filter = store.getters["programacion/filter"];
      const search = store.getters["programacion/search"];

      isLoading.value = true;
      ProgramacionApi.getAll({ currentPage }, filter, search)
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      store.dispatch("programacion/cleanFilters");
      UsuariosApi.userLogAdd("Vigilancia", "Programación de envio de SMS", establecimiento);
      fetchData();
    });

    const handleChangeState = () => {
      const activateMsg = mensaje.value.activo === "1" ? "desactivará" : "activará";
      const actionMsg = mensaje.value.activo === "1" ? "desactivado" : "activado";

      Modal.confirm({
        title: () => "¿Estás seguro de cambiar el estado?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se ${activateMsg} el mensaje ${mensaje.value.mensaje}`,
        okText: () => "Sí, cambiar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const valor = mensaje.value.activo === "1" ? "0" : "1";
          ProgramacionApi.CambiarEstadoOne(toRaw(mensaje.value.idprogramacion), valor)
            .then((res) => {
              fetchData(currentPage.value);
              clearSelectedArrays();

              notification.success({
                message: "Operación exitosa",
                description: `Se ha ${actionMsg} el mensaje satistactoriamente.`
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el mensaje '${mensaje.value.mensaje}'`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          ProgramacionApi.deleteOne(toRaw(mensaje.value.idprogramacion))
            .then((res) => {
              fetchData(currentPage.value);
              clearSelectedArrays();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    watch(currentPage, () => {
      fetchData(currentPage.value);
    });

    watch(state, () => {
      isDisabled.value = !state.selectedRowKeys.length;
    });

    const reloadFetch = () => {
      fetchData(currentPage.value);
    };

    // const routes = [
    //   {
    //     path: "menu",
    //     breadcrumbName: "Vigilancia"
    //   },
    //   {
    //     path: "/mensajes",
    //     breadcrumbName: "Programación de envíos SMS"
    //   }
    // ];

    return {
      // goBack,
      // routes,
      columns,
      data,
      customRow,
      rowSelection,
      isModalVisible,
      isDisabled,
      handleScript,
      handleAdd,
      isLoading,
      handleEdit,
      reloadFetch,
      fetchData,
      handleChangeState,
      handleDelete,
      clearSelectedArrays,
      mensaje,
      pageSize,
      currentPage,
      totalDocs,
      closeModal
    };
  }
};
</script>

<style></style>
