import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";

export default {
  getAll: (payload, filter, search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_sms_programacion",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        },
        {
          name: "@filtros_AND",
          value: "p.activo",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${filter}%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "sg.denominacion|sm.denominacion|pr.denominacion",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getMensajes: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_mensajes",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getPeriocidad: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_periodicidad",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getGrupo: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_sms_grupo",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  editOne: (payload, idsmsprogramacion, mensaje) => {
    const request = {
      _type: 3,
      _table: "sms_programacion",
      _petitions: [
        {
          name: "idmensaje",
          value: payload.idmensaje,
          type: "string"
        },
        {
          name: "frecuencia",
          value: payload.frecuencia,
          type: "int"
        },
        {
          name: "idgrupo",
          value: payload.idgrupo,
          type: "int"
        },
        {
          name: "idperiodicidad",
          value: payload.idperiodicidad,
          type: "int"
        },
        {
          name: "mensaje",
          value: mensaje,
          type: "string"
        }
      ]
    };

    return put(API_SERVICE + "/" + idsmsprogramacion, request);
  },

  addOne: (payload, mensaje) => {
    const request = {
      _type: 2,
      _table: "sms_programacion",
      _petitions: [
        {
          name: "idmensaje",
          value: payload.idmensaje,
          type: "string"
        },
        {
          name: "frecuencia",
          value: payload.frecuencia,
          type: "int"
        },
        {
          name: "idgrupo",
          value: payload.idgrupo,
          type: "int"
        },
        {
          name: "idperiodicidad",
          value: payload.idperiodicidad,
          type: "int"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        },
        {
          name: "mensaje",
          value: mensaje,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE, request);
  },

  deleteOne: (idsmsprogramacion) => {
    const request = {
      _type: 3,
      _table: "sms_programacion",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idsmsprogramacion, request);
  },

  CambiarEstadoOne: (idsmsprogramacion, activo) => {
    const request = {
      _type: 3,
      _table: "sms_programacion",
      _petitions: [
        {
          name: "activo",
          value: activo,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + idsmsprogramacion, request);
  }
};
