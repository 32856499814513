import axios from "axios";
import store from "@/store";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_GOIP_URL,
  timeout: 1200000
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // console.log(response);
    const res = response.data;

    // if the custom status is not 200, it is judged as an error.
    if (res.status !== 200) {
      // alert(res.message);
      console.log(res);

      return Promise.reject(new Error(res.mensaje || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    // judge for http status
    // if(status === 422){store.dispath("snackbar/showError", {text})}

    if (error.response) {
      // console.log("error response=> ", error.response);
      const [{ mensaje }] = error.response.data.errores;

      return Promise.reject(new Error(mensaje || "Problemas con la petición inténtelo más tarde"));
    }
    // message.error("Tenemos problemas con el servidor, por favor inténtelo más tarde");
    return;
  }
);

const { get, post, put, patch, delete: destroy } = service;

export { get, post, put, patch, destroy };

export default service;
