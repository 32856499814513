export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE GRUPO",
    dataIndex: "grupo",
    key: "grupo",
    width: 180,
    ellipsis: true
  },
  {
    title: "MENSAJE",
    dataIndex: "mensaje",
    key: "mensaje",
    width: 180,
    ellipsis: true
  },
  {
    title: "PERIOCIDAD",
    dataIndex: "periocidad",
    key: "periocidad",
    width: 100,
    ellipsis: true
  },
  {
    title: "FRECUENCIA",
    dataIndex: "frecuencia",
    key: "frecuencia",
    width: 100,
    ellipsis: true
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" },
    width: 120
  }
];

export const data = [
  {
    idmensaje: "1",
    key: "1",
    destinatarios: "MADRES 3 MESES",
    mensaje:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat dolores inventore consectetur sequi porro ut fugit aspernatur minima",
    periodicidad: "Semanal",
    frecuencia: "5",
    modofrecuencia: "Antes",
    activo: "1"
  }
];
