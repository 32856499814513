<template>
  <a-modal
    :visible="true"
    title="Nueva programación"
    class="max-w-sm md:max-w-lg"
    @ok="handleOk"
    @close="closeModal"
    @cancel="handleCancel"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <a-form-item label="Grupo" name="idgrupo">
        <a-select v-model:value="formState.idgrupo">
          <a-select-option value="" disabled="">Seleccione su grupo</a-select-option>
          <a-select-option
            :value="grupo.idgrupo"
            v-for="grupo in grupos"
            :key="grupo.idgrupo"
            :title="grupo.denominacion"
          >
            {{ grupo.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Mensaje" name="idmensaje">
        <a-select v-model:value="formState.idmensaje" @change="handleChange">
          <a-select-option value="" disabled="">Seleccione un mensaje estándar</a-select-option>
          <a-select-option
            :value="mensaje.idmensaje"
            v-for="mensaje in mensajes"
            :key="mensaje.idmensaje"
            :title="mensaje.idmensaje"
          >
            {{ mensaje.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="mensajemax" name="mensaje">
        <a-textarea
          v-model:value="descripcion"
          :rows="3"
          :maxlength="maxlength"
          :value="formState.mensaje"
        />
      </a-form-item>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <a-form-item label="Periocidad" name="idperiodicidad">
          <a-select v-model:value="formState.idperiodicidad">
            <a-select-option value="" disabled="">Seleccione su periocidad</a-select-option>
            <a-select-option
              :value="periocidad.idperiodicidad"
              v-for="periocidad in periocidades"
              :key="periocidad.idperiodicidad"
              :title="periocidad.abreviatura"
            >
              {{ periocidad.denominacion }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Frecuencia" name="frecuencia">
          <a-input v-model:value="formState.frecuencia" type="number" />
        </a-form-item>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-3"></div>
    </a-form>
  </a-modal>
</template>

<script>
import ProgramacionApi from "@/api/sms_programacion";
import { notification } from "ant-design-vue";
import { reactive, onMounted, toRaw, ref } from "vue";
import UserApi from "@/api/user";

export default {
  props: {
    mensaje: {
      type: Object,
      default: null
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  emits: ["closeModal", "fetchData", "clearSelectedArrays"],
  setup(props, { emit }) {
    const mensajes = ref([]);
    const periocidades = ref([]);
    const grupos = ref([]);
    const formRef = ref();
    const maxlength = ref(10);
    const descripcion = ref("");
    const mensajemax = ref("");
    const formState = reactive(props.mensaje);
    const isEdit = !!props.mensaje.idprogramacion;

    const closeModal = () => {
      emit("closeModal");
      console.log("Hola cerrar modal");
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);

          let response = {};

          try {
            if (isEdit) {
              response = await ProgramacionApi.editOne(
                payload,
                payload.idprogramacion,
                descripcion.value
              );

              if (response.status == 200) {
                notification.success({
                  message: "Operación exitosa",
                  description: "Registro programación envio SMS editado"
                });
              }

              emit("clearSelectedArrays");
              emit("closeModal");
              emit("fetchData", props.currentPage);
            } else {
              response = await ProgramacionApi.addOne(payload, descripcion.value);

              if (response.status == 200) {
                notification.success({
                  message: "Operación exitosa",
                  description: "Registro programación envio SMS creado"
                });
              }

              emit("clearSelectedArrays");
              emit("closeModal");
              emit("fetchData", props.currentPage);
            }
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const handleChange = (value) => {
      var data = mensajes.value.find((p) => p.idmensaje === value);
      console.log("handleChange");
      console.log(value);
      console.log(data.descripcion);
      descripcion.value = data.descripcion;
      //formState.mensaje = descripcion.value;
      console.log(descripcion.value);
    };

    const fetchData = () => {
      ProgramacionApi.getMensajes()
        .then((response) => {
          mensajes.value = response.data;

          if (isEdit) {
            descripcion.value = formState.mensaje;
          } else {
            handleChange(formState.idmensaje);
          }
        })
        .catch((err) => console.log(err));

      ProgramacionApi.getPeriocidad()
        .then((response) => {
          periocidades.value = response.data;
        })
        .catch((err) => console.log(err));

      ProgramacionApi.getGrupo()
        .then((response) => {
          grupos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      fetchData();

      UserApi.getParametroxDenominacion("TEXTO_MENSAJE")
        .then((res) => {
          maxlength.value = Number(res.data[0].valor);
          mensajemax.value = "Contenido mensaje (máx. " + res.data[0].valor + " carácteres)";
        })
        .catch((err) => console.log(err));
    });

    const rules = {
      idgrupo: [
        {
          required: true,
          message: "El campo grupo debe ser seleccionado"
        }
      ],
      idmensaje: [
        {
          required: true,
          message: "El campo mensaje no puede estar vacío"
        }
      ],
      idperiodicidad: [
        {
          required: true,
          message: "El campo periodicidad debe ser seleccionado"
        }
      ],
      frecuencia: [
        {
          required: true,
          message: "El campo frecuencia no puede estar vacío"
        }
      ]
    };

    const layout = {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      }
    };

    return {
      layout,
      formState,
      maxlength,
      closeModal,
      grupos,
      periocidades,
      formRef,
      handleOk,
      rules,
      descripcion,
      mensajemax,
      handleChange,
      mensajes,
      handleCancel
    };
  }
};
</script>
