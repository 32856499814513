<template>
  <button @click="handleScript" class="text-center px-3">
    <div class="text-3xl text-purple-400">
      <FileZipOutlined />
    </div>
    <p>Ejecutar</p>
  </button>

  <button @click="handleAdd" class="text-center px-3">
    <div class="text-3xl text-green-500">
      <FileAddOutlined />
    </div>
    <p>Nuevo</p>
  </button>

  <button @click="handleEdit" class="text-center px-3" :disabled="isDisabled">
    <div
      class="text-3xl"
      :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
    >
      <FormOutlined />
    </div>
    <p>Editar</p>
  </button>

  <button @click="handleDelete" class="text-center px-3" :disabled="isDisabled">
    <div
      class="text-3xl"
      :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-red-400']"
    >
      <DeleteOutlined />
    </div>
    <p>Eliminar</p>
  </button>

  <button @click="handleChangeState" class="text-center px-3" :disabled="isDisabled">
    <div
      class="text-3xl"
      :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-400']"
    >
      <IssuesCloseOutlined />
    </div>
    <p>{{ actionMessage }}</p>
  </button>
</template>

<script>
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  IssuesCloseOutlined,
  FileZipOutlined
} from "@ant-design/icons-vue";
import { computed } from "vue";

export default {
  components: {
    FileAddOutlined,
    FormOutlined,
    FileZipOutlined,
    DeleteOutlined,
    IssuesCloseOutlined
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
      required: true
    },
    mensaje: {
      type: Object
    }
  },

  emits: ["handleAdd", "handleEdit", "handleDelete", "handleChangeState", "handleScript"],
  setup(props, { emit }) {
    const actionMessage = computed(() =>
      props.mensaje?.activo === "1" ? "Desactivar" : "Activar"
    );

    return {
      actionMessage,
      handleAdd: () => emit("handleAdd"),
      handleEdit: () => emit("handleEdit"),
      handleDelete: () => emit("handleDelete"),
      handleChangeState: () => emit("handleChangeState"),
      handleScript: () => emit("handleScript"),
    };
  }
};
</script>

<style></style>
